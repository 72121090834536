import React from 'react';
import Card from '../Card/Card';
import { Row, Col } from 'reactstrap';
import { getLastNew, getLastArticle, getLastProject } from '../../reducers/Home/actions'
import { connect } from 'react-redux'
import { getArticleDetails } from '../../reducers/Articles/actions'
import { getNewsDetails } from '../../reducers/News/actions'
import { getProjectDetails } from '../../reducers/Projects/actions'
import houseImage2 from '../../assets/images/house2.jpg';
import videoDefaultImage from '../../assets/images/video-thumb-01.jpg'
import { graphql } from 'gatsby';
import './styles.scss';

const RecentNews = ({
  staticLastArticle,
  staticLastNew,
  staticLastProject,
  getArticleDetails,
  getNewsDetails,
  getProjectDetails,
  title
}) => {

  return (
    <div className='main-container'>
      <div className='recent-news-container'>
        <div className='text-container'>
          <h2 className="font-weight-bold">{title ? title : 'Последни публикации'}</h2>
        </div>
        <div>
          <Row className="justify-content-center">
            {
              staticLastProject &&
              staticLastProject.map((project, i) => (
                <Col
                  key={i}
                  className='recent-news-col'
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <Card
                    cardClass="recent-news-card"
                    serviceInfo='избрани проекти'
                    cardBodyClass="recent-news-card-body hide-description"
                    btnClass='recent-news-card-btn'
                    url={`/project-details/${project.node && project.node.id}`}
                    title="Авторски проект"
                    subtitle={project.node && project.node.author_name}
                    desc=""
                    isGatsbyImage={true}
                    image={
                      project && project.node && project.node.image.localFile && project.node.image.localFile.childImageSharp
                        ? project.node.image.localFile.childImageSharp
                        : houseImage2
                    }
                    onClick={() => getProjectDetails({ id: project.node.id })}
                  />
                </Col>
              ))
            }
            {
              staticLastNew &&
              staticLastNew.map((news, i) => (
                <Col
                  key={i}
                  className='recent-news-col'
                  sm={12} md={6}
                  lg={4}
                >
                  <Card
                    cardClass="recent-news-card"
                    serviceInfo='видео'
                    cardBodyClass='recent-news-card-body'
                    btnClass='recent-news-card-btn'
                    url={`/videos`}
                    title='Избрани видеа'
                    desc=''
                    isGatsbyImage={false}
                    image={videoDefaultImage}
                    onClick={() => { }}
                  />
                </Col>
              ))
            }
            {/* {
              staticLastNew && 
              staticLastNew.map((news, i) => (
                <Col
                  key={i}
                  className='recent-news-col' 
                  sm={12} md={6} 
                  lg={4}
                >
                  <Card
                    cardClass="recent-news-card"
                    serviceInfo='новини'
                    cardBodyClass='recent-news-card-body'
                    btnClass='recent-news-card-btn'
                    url={`/news-single/${news.node && news.node.id}`}
                    title={news && news.node && news.node.title}
                    desc={news && `${news.node.description.substr(0, 70)}...`}
                    isGatsbyImage={true}
                    image={
                      news && news.node.image.localFile && news.node.image.localFile.childImageSharp
                        ? news.node.image.localFile.childImageSharp
                        : houseImage2
                    }
                    onClick={() => getNewsDetails({ id: news.node.id })}
                  />
                </Col>
              ))
            } */}
            {
              staticLastArticle &&
              staticLastArticle.map((article, i) => (
                <Col
                  key={i}
                  className='recent-news-col'
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <Card
                    cardClass="recent-news-card"
                    serviceInfo='статии'
                    cardBodyClass='recent-news-card-body'
                    btnClass='recent-news-card-btn'
                    url={`/articles-single/${article.node && article.node.id}`}
                    title={article && article.node.title}
                    desc={article && `${article.node.description.substr(0, 70)}...`}
                    isGatsbyImage={true}
                    image={
                      article && article.node.image.localFile && article.node.image.localFile.childImageSharp
                        ? article.node.image.localFile.childImageSharp
                        : houseImage2
                    }
                    onClick={() => getArticleDetails({ id: article.node.id })}
                  />
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getLastNew,
  getLastArticle,
  getLastProject,
  getArticleDetails,
  getNewsDetails,
  getProjectDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentNews);

export const query = graphql`
  {
    articlesLast: allStrapiArticleslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: AUTO)
              }
            }
          }
        }
      }
    },
    newsLast: allStrapiNewslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
    projectsLast: allStrapiProjectslast {
      edges {
        node {
          id: strapiId
          title
          author_name
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

