import React from "react";
import { Button, Container, Modal, ModalBody } from "reactstrap";
import { postBePartner } from '../../../reducers/Home/actions'
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.scss";

const ConfirmationModal = ({ isOpen, onClick, title, text, openFromGuides }) => {
  const replaceHistory = () => {
    if (typeof window !== "undefined" && isOpen === true && openFromGuides === true) {
      window.history.replaceState(null, "", "/building-guide");
    }
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
        <Modal size="lg" className="modal-element" isOpen={isOpen} centered>
          <div className="close-btn d-flex justify-content-end">
            <i
              role="button"
              tabIndex={0}
              className="close-btn"
              onKeyDown={onClick}
              onClick={onClick}>
              <CloseIcon />
            </i>
          </div>
          <Container tag="h4" className="no-decoration text-center px-5 py-3">
            {title}
          </Container>
          <ModalBody className="modal-body no-decoration w-75 text-center justify-items-center margin-0-auto">
            <p className="pb-1 text-black-50">{text}</p>
          </ModalBody>
          <Container className="d-flex justify-content-center align-items-center pb-3 pt-1 flex-column flex-xl-row w-75">
            <Button
              className=" mx-xl-4 w-25 py-3 text-uppercase partner-modal-button"
              onClick={() => {
                replaceHistory();
                onClick();
              }}
            >
              Ok
            </Button>
          </Container>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  postBePartner
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
